<template>
  <div class="app-container">
    <navBar :title="'选择部门'" @handleGoBack="handleGoBack">
      <template v-slot:icon2>
        <div class="confirm-btn" @click="handleConfirmClick">确定</div>
      </template>
    </navBar>
    <div class="wrap">
      <van-search class="search-input" v-model="value" placeholder="请输入搜索关键词" />
    </div>
    <vs-tree
      v-if="data.length > 0"
      class="my-tree"
      :data="data"
      ref="tree"
      showCheckbox
      highlightCurrent
      accordion
      animation
      @change="handleNodeChange"
    ></vs-tree>
  </div>
</template>
<script>
import { treeselect } from '@/api/system/dept';
export default {
  data() {
    return {
      value: null,
      data: [],
    };
  },

  created() {
    this.getTreeSelect();
  },

  methods: {
    handleGoBack() {
      this.$router.replace('/attendance/ruleSet/0');
        this.$router.go(-1)
    },

    async getTreeSelect() {
      const result = await treeselect();
      this.data = this.handleName(result.data);
    },

    handleName(list) {
      const newList = list.map((ele) => {
        return {
          name: ele.label,
          id: ele.id,
          children: ele.children && ele.children.length > 0 ? this.handleName(ele.children) : [],
        };
      });
      return newList;
    },

    handleNodeChange() {
      const nodes = this.$refs.tree.getCheckedNodes();
      // const applyIdList = nodes.map((ele) => ele.id);
      this.$store.commit('attendance/SET_APPLY_ID_LIST', nodes);
    },

    handleConfirmClick() {
      this.$router.push({
        path: '/attendance/ruleSet/0',
        query: {
          sign: 'dept',
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .vs-tree-node {
  font-size: 30px;
  height: 80px;
}
::v-deep .vs-checkbox__inner,
::v-deep .vs-radio__inner {
  width: 30px;
  height: 30px;
}
::v-deep .expand,
::v-deep .expand-empty {
  width: 100px;
}

::v-deep .expand.vs-expand-icon:after {
  width: 40px;
  height: 30px;
  background-size: 100% 100%;
  margin-top: -8px;
}
::v-deep .vs-checkbox__inner:after {
  width: 15px;
  height: 15px;
  top: 2px;
}
::v-deep .is-indeterminate .vs-checkbox__inner:before {
  top: 7px;
}
.wrap {
  background: #fff;
}
.search-input {
  width: 710px;
  margin: 0 auto;
}
.my-tree {
  background: #fff;
  margin-top: 20px;
}

.confirm-btn {
  width: 100px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  background: #0091ff;
  color: #fff;
  font-size: 24px;
  border-radius: 5px;
}
</style>
